
<template>
  <App
    title="待分配Co-Pilot"
    left-arrow
  >
    <List
      v-model="loading"
      :finished="finished"
      finished-text="No more items."
      @load="getPoiltList"
    >
      <Card
        v-for="item in list"
        :key="item.testDriveId"
        @click.native=poilt(item)
      >
        <Cell :title="item.accountName" />
        <Cell>
          <template #title>
            <CardCell
              :label="item.appointmentDate"
              :value="item.appointmentTime"
              :colon="false"
            />
          </template>
        </Cell>
        <Cell>
          <template #title>
            <CardCell
              label="归属顾问"
              :value="item.ownerName"
            />
          </template>
          <template #right-icon>
            <Icon
              name="arrow"
              color="#DA3738"
            ></Icon>
          </template>
        </Cell>

      </Card>
    </List>
  </App>
</template>
<script>
import { List, Toast, Cell, Icon } from 'vant'
import Card from '_c/card'
import CardCell from '_c/card/cardCell'
import { unPoiltList } from '@/api/testdrive/index'
export default {
  name: 'coPilot',
  components: {
    List,
    Card,
    CardCell,
    Cell,
    Icon
  },
  data () {
    return {
      list: [],
      loading: false,
      finished: true,
    }
  },
  methods: {
    // 点击跳转到分配页面
    poilt (item) {
      this.$router.push({
        path: '/coPilotAllot',
        query: {
          code: item.storeCode,
          id: item.testDriveId
        }
      })
    },
    // 获取列表
    async getPoiltList () {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      const res = await unPoiltList({
        'code': this.$storage.get('orgCode'),
        page: 1,
        size: 10
      })
      Toast.clear()
      if (res.success) {
        this.list = res.data.records
      } else {
        Toast(res.msg)
      }
    }
  },
  mounted () {
    this.getPoiltList()
  }
}
</script>